.login-layout {
	.input-text {
		position: relative;
		input {
			width: 318px;
			height: 56px;
			border-radius: 4px;
			padding-left: 16px;
			border: 1px solid black;
			&:focus {
				outline: none;
			}
			&::placeholder {
				color: grey;
			}
		}

		.placeholder-text {
			position: absolute;
			top: -8px;
			left: 12px;
			background-color: #d3d3d3;
			font-size: 12px;
			font-weight: normal;
			padding: 0 4px;
			color: #000;
		}
	}
	.reset-password {
		margin-top: 20px;
	}
	.login-email {
		margin-top: 50px !important;
	}
	.error-message {
		color: red;
		font-size: 12px;
		position: absolute;
		bottom: -14px;
	}
	.login-button {
		width: 318px;
		height: 56px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #3498d3;
		border-radius: 4px;
		margin-top: 25px;
		border: none;
		color: #fff;
		gap: 12px;
		font-weight: bold;
		font-size: 12px;
		cursor: pointer;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
		cursor: pointer;
	}
}
