.sidebar {
	width: 250px;
	height: 100vh;
	float: left;
	padding: 46px 10px 0 30px;
	position: fixed;

	img {
		font-size: 32px;
		color: red;
		font-weight: bold;
		max-width: 165px;
		height: 59px;
	}
	ul {
		margin-top: 24px;
		padding-inline-start: 0;
		li {
			list-style: none;
			padding: 20px 12px;
			color: #000;
			font-size: 12px;
			font-weight: normal;
			text-transform: uppercase;
			cursor: pointer;
			&:hover {
				background-color: #d9d9d9;
			}
		}
		li.active {
			background-color: #d9d9d9;
			font-weight: bold;
			color: #333;
		}
		.disabled-plan {
			color: gray;
			cursor: not-allowed !important;
		}
		a {
			text-decoration: none;
			color: #424242;
		}
	}
}

.sidebar-mobile-container {
	img {
		font-size: 32px;
		color: red;
		font-weight: bold;
		max-width: 155px;
		height: 59px;
	}
	ul {
		margin-top: 24px;
		padding-inline-start: 0;
		li {
			list-style: none;
			padding: 20px 12px;
			color: #000;
			font-size: 12px;
			font-weight: normal;
			text-transform: uppercase;
			cursor: pointer;
			&:hover {
				background-color: #d9d9d9;
			}
		}
		li.active {
			background-color: #d9d9d9;
			font-weight: bold;
			color: #333;
		}
		.disabled-plan {
			color: gray;
			cursor: not-allowed !important;
		}
		a {
			text-decoration: none;
			color: #424242;
		}
	}
	.content-wrapper {
		transition: margin-left 0.3s ease;

		&.menu-open {
			margin-left: 250px;
		}
	}
}

.bm-burger-button {
	position: fixed;
	width: 36px;
	height: 30px;
	left: 15px;
	top: 15px;
	cursor: pointer;
	z-index: 1000;
}

.bm-burger-bars {
	background: #373a47;
	height: 3px;
}

.bm-cross-button {
	height: 24px !important;
	width: 24px !important;
	right: 15px !important;
	top: 15px !important;
}

.bm-cross {
	background: #333;
	height: 25px !important;
}

.bm-menu {
	background: white;
	padding: 46px 20px 0 20px;
	font-size: 12px;
}

.bm-menu-wrap {
	top: 0;
}

.bm-overlay {
	background: rgba(0, 0, 0, 0.3);
}

.bm-menu-wrap {
	transition: all 0.3s ease !important;
}
.sidebar-mobile-container {
	.mobile-icons-sidebar {
		display: flex;
		flex-direction: column;
		width: 60px;
		min-width: 60px;
		background-color: #fff;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 50px;
		z-index: 100;
		padding-top: 60px;

		.icon-item {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 15px 0;
			cursor: pointer;

			a,
			div {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #555;
				&:hover {
					color: #000;
				}
			}
		}
	}
	.content-wrapper {
		transition: margin-left 0.3s ease;
		margin-left: 60px;

		&.menu-open {
			margin-left: 250px;
		}
	}
}
