.login-layout-button {
	width: 318px;
	height: 56px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #3498d3;
	border-radius: 4px;
	margin-top: 25px;
	border: none;
	color: #fff;
	gap: 12px;
	font-weight: bold;
	font-size: 12px;
	cursor: pointer;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	@media screen and (max-width: 600px) {
		width: 280px;
	}
}
