* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.main-layuot {
	width: calc(99% - 250px);
	margin-left: 250px;
	margin-right: 20px;
	border-radius: 6px;
	overflow: auto;
	padding: 30px;
	@media screen and (max-width: 768px) {
		margin-left: 60px;
		margin-right: 0px;
		max-width: 100%;
		width: calc(99% - 60px);
		padding: 10px;
	}
}
.red-text {
	color: red;
	font-size: 12px;
}
.input-text {
	position: relative;
	input {
		width: 318px;
		height: 56px;
		border-radius: 4px;
		padding: 0 16px;
		border: 1px solid black;
		@media screen and (max-width: 600px) {
			width: 280px;
		}
	}
	textarea {
		width: 318px;
		min-height: 80px;
		border-radius: 4px;
		padding: 10px 16px;
		border: 1px solid black;
		@media screen and (max-width: 600px) {
			width: 280px;
		}
	}
	input:focus {
		outline: none;
	}
	input::placeholder {
		color: grey;
	}
}

.placeholder-text {
	position: absolute;
	top: -8px;
	left: 12px;
	background-color: #d3d3d3;
	font-size: 12px;
	font-weight: normal;
	padding: 0 4px;
	color: #000;
}
.login-layout button {
	width: 318px;
	height: 56px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #3498d3;
	border-radius: 4px;
	margin-top: 25px;
	border: none;
	color: #fff;
	gap: 12px;
	font-weight: bold;
	font-size: 12px;
	cursor: pointer;
}
.error-message {
	color: red;
	font-size: 12px;
	position: absolute;
	bottom: -14px;
}

.barcode-login {
	color: #3498d3;
	font-size: 16px;
	font-weight: normal;
	margin-top: 70px;
	cursor: pointer;
	text-align: center;
}
.login-email {
	margin-top: 50px !important;
}
.login-layout {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 50px;
	height: 100dvh;
}
.login-layout > img {
	max-width: 251px;
	margin-bottom: 51px;
}

.wrapper {
	position: relative;
	height: min-content;

	input {
		margin-bottom: 26px;
	}
	.last-field {
		margin-bottom: 0 !important;
	}
	.error-message {
		position: absolute;
		top: 57px !important;
		z-index: 9999;
	}

	.global-error {
		position: absolute;
		bottom: -18px !important;
	}
	.logo {
		margin-bottom: 51px;
	}
}

.profile-layout {
	margin-left: 181px;
	margin-top: 50px;
	@media (max-width: 1200px) {
		margin-left: 0px !important;
	}
}

.input-text select {
	width: 318px;
	height: 40px;
	padding: 0 8px;
	border-radius: 6px;
	border: 1px solid #7d7f7c;
	appearance: auto;
	@media screen and (max-width: 600px) {
		width: 280px;
	}
}
.edit-profile {
	color: #3498d3;
	font-size: 16px;
	font-weight: 400;
	margin-top: 36px;
	cursor: pointer;

	h2 {
		margin-bottom: 30px;
		color: #000;
	}
}
.plan-list {
	max-width: 1000px;
	margin-top: 50px;

	h2 {
		font-size: 24px;
		margin-bottom: 20px;
	}

	.plan-list-container {
		display: flex;
		flex-direction: column;
		max-height: 600px;
		overflow-y: auto;
		.plan-description {
			max-width: 360px;
		}
	}
}
.add-plan-section {
	display: none;
	align-items: center;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	position: relative;
	padding: 12px;
	width: 100%;
	max-width: 360px;
	margin-top: 20px;
	.background-image {
		width: 100%;
		height: 150px;
		background-size: cover;
		background-position: center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
	}

	.plan-image {
		width: 120px;
		height: auto;
		margin-left: 12px;
		z-index: 1;
		transform: rotate(-10deg);
	}

	.text-container {
		flex: 1;
		padding: 12px;
		position: relative;
		z-index: 2;
	}

	.add-plan-title {
		font-size: 18px;
		font-weight: bold;
		color: #fff;
		margin-bottom: 4px;
		cursor: pointer;
	}

	.add-plan-subtext {
		font-size: 12px;
		color: #fff;
	}
}
