.toast-box {
	width: 100%;
	max-width: 500px !important;
	.closebtn {
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
	}

	.Toastify__toast-container--bottom-right,
	.Toastify__toast-container--tops-right {
		width: 500px !important;
	}
	.inner-toast {
		display: flex;
		justify-content: left;
		gap: 16px;
		width: 500px;
		.logo-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			align-self: center;
			.success-color {
				color: #00875a;
				font-size: 16px;
				font-weight: 700;
				margin: 0px;
			}
			.warning-color {
				color: #ff991f;
				font-size: 16px;
				font-weight: 700;
				margin: 0px;
			}
			.error-color {
				color: #b22929;
				font-size: 16px;
				font-weight: 700;
				margin: 0px;
			}
			.toast-msg {
				font-size: 14px;
				color: #000;
				width: 250px;
			}
		}
	}
	.content-toast {
		margin-top: 0px;
	}
}
